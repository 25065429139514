<template>
	<div class="all_detail">
		<div class="detail">
			<div class="detail_right">
				<div class="top">
					<div class="top_left">
						<div class="title" @click="backClick">
							{{$t('title.myRelease')}}
						</div>
						<div class="img">
							<img src="@/assets/home_icons/front.png">
						</div>
						<div class="sub_title">{{$t('title.detail')}}</div>
					</div>
				</div>
				<div class="carousel">
					<el-carousel trigger="click" height="350px" arrow="always">
						<el-carousel-item v-if="video">
							<video width="894" height="350" :src="video" :poster="cover" controls :autoplay="true"
								muted="">
							</video>
						</el-carousel-item>
						<el-carousel-item v-for="(item,index) in thumb" :key="index">
							<viewer :images="[item]">
								<img style="object-fit: contain;width: 894px;height: 350px;" :src="item" :key="index">
							</viewer>
						</el-carousel-item>
					</el-carousel>
					</el-carousel>
				</div>
				<div class="house_content">
					<div class="house_title">
						<div class="house_title_left">
							<div class="house_name">
								{{inforDetail.title}}
							</div>
							<div class="house_two">
								<div class="price" v-if="inforDetail.price!='0.00'">
									${{inforDetail.price}}
								</div>
								<div class="address" :style="{marginLeft:inforDetail.price!='0.00'?80+'px': 0+'px'}">
									<img src="@/assets/home_icons/house.png">
									<div class="address_detail">
										{{inforDetail.addr_name}} {{inforDetail.area_name}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="house_info">
						<div class="house_time">
							{{$t('home.releaseTime')}} {{inforDetail.create_time}}
						</div>
					</div>
				</div>

				<div class="all_info" v-if="skuData.length>0">
					<div class="info_item" v-for="(item,index) in skuData" :key="index">
						<div class="info_item_title">{{item.key_name}}</div>
						<div class="info_item_content">{{item.select_value?item.select_value:item.value}}</div>
					</div>
				</div>
				<div class="home_detail">
					<div class="home_detail_content">
						{{inforDetail.detail}}
					</div>
					<div class="home_detail_imgs">
						<div class="detail_img">
							<viewer :images="thumb">
								<img :src="item" v-for="(item,index) in thumb" :key="index">
							</viewer>
						</div>
					</div>
				</div>

				<div class="status_btn" v-if="check==2" style="margin-bottom: 5px;">
					<el-switch :active-text="$t('isStop')" @change="switchChange()" active-color="#ff0000"
						inactive-color="#eeeeee" v-model="status">
					</el-switch>
				</div>

				<div class="status_btn apply_in" v-if="check==1">
					{{$t('curState')}}：{{$t('other.applyIn')}}
				</div>
				<div class="status_btn apply_success" v-if="check==2">
					{{$t('curState')}}：{{$t('other.applySuccess')}}
				</div>
				<div class="status_btn apply_fail" v-if="check==3">
					{{$t('curState')}}：{{$t('other.applyFailure')}}
				</div>
				<div class="status_btn apply_btn" v-if="check==3" @click="resubmitClick">
					{{$t('submitAgain')}}
				</div>
				<div class="status_btn apply_success2" v-if="check==2" @click="resubmitClick">
					{{$t('my.edit')}}
				</div>
			</div>
		</div>


	</div>
</template>

<script>
	export default {
		data() {
			return {
				inforDetail: '', //信息详情
				skuData: '', //
				thumb: [], //
				video: '', //视频地址
				cover: '', //封面地址
				check: '', //
				status: '', //是否停用
			}
		},
		mounted() {
			this.information_id = this.$route.query.information_id;
			this.getInforDetailDetail();
			window.addEventListener("setItemEvent", (e) => {
				if (e.key === "lang") {
					this.getInforDetailDetail();
				}
			})
		},
		methods: {
			// 是否停用
			switchChange() {
				this.$http.isStopMessage({
					id: this.information_id,
				}).then(res => {
					if (res.code == 1) {

					} else {
						this.$message.info(res.msg);
					}
				})
			},
			// 获取信息详情
			getInforDetailDetail() {
				let userId = this.$store.state.userinfo.id;
				console.log(userId);
				this.$http.informationDetail({
					user_id: userId,
					information_id: this.information_id,
				}).then(res => {
					if (res.code == 1) {
						this.inforDetail = res.data;
						this.skuData = res.data.sku_data;

						let newSkuData = [];
						this.skuData.forEach((item, index) => {
							if ((item.information_type == 1 && !item.value) || (item.information_type ==
									3 && !item.value)) {} else {
								newSkuData.push(item);
							}
						})
						newSkuData.forEach(item => {
							if (item.sign == 'Precio') {
								item.value = '$' + item.value;
							}

							if (item.sign == 'Mantenimientos') {
								item.value = '$' + item.value;
							}
						})
						this.skuData = newSkuData;


						this.thumb = res.data.thumb;
						this.video = res.data.video;
						this.cover = res.data.cover;
						this.status = res.data.status == 1 ? false : true;
						this.check = this.inforDetail.check;
					} else {
						this.$message.error(res.msg);
					}
				})
			},

			backClick() {
				this.$router.push({
					path: '/myRelease',
					query: {
						curCenterIndex: 3,
					}
				})
			},
			// 重新提交
			resubmitClick() {
				this.$router.push({
					path: '/resubmitRelease',
					query: {
						information_id: this.information_id,
					}
				})
			}
		}
	}
</script>

<style scoped="" lang="less">
	.title_all {
		display: flex;
		align-items: center;
		font-size: 20px;
		font-weight: bold;

		.title_img {
			margin-right: 8px;

			img {
				display: flex;
				align-items: center;
				width: 24px;
				height: 24px;
			}
		}
	}

	.dialog_content {
		text-align: left;

		.inform_title {
			font-size: 16px;
			font-weight: bold;
			color: #333333;
			margin-bottom: 16px;
		}

		.textarea {
			margin-bottom: 24px;
			width: 604px;
			height: 200px;
			background: #F6F7F9;
			width: 604px;
			height: 200px;
			background: #F6F7F9;
			padding: 24px;
			position: relative;

			/deep/ .el-textarea {
				textarea {
					outline: none;
					background: #F6F7F9;
					border: none;
					font-size: 14px;
					resize: none;
					font-family: Microsoft YaHei;
				}
			}

			.count {
				font-size: 12px;
				color: #999999;
				position: absolute;
				bottom: 16px;
				right: 16px;
			}
		}


		/deep/ .el-upload--picture-card {
			width: 140px;
			height: 140px;
		}

		/deep/ .el-upload__tip {
			width: 140px;
			text-align: center;
		}

		.btn {
			width: 112px;
			height: 40px;
			text-align: center;
			line-height: 40px;
			background: #E60012;
			opacity: 1;
			border-radius: 20px;
			font-size: 14px;
			margin: 32px auto 24px;
			color: #FFFFFF;
		}

		.tip {
			margin: 0 auto;
			word-break: break-all;
			max-width: 466px;
			font-size: 14px;
		}
	}


	.all_detail {
		background: #F6F7F9;
		padding-top: 24px;
	}

	.detail {
		box-sizing: border-box;
		width: 1200px;
		margin: 0 auto;
		display: flex;


		.detail_right {
			margin-bottom: 64px;
			width: 1200px;
			background-color: #FFFFFF;
			padding: 37px 32px;

			.top {
				display: flex;
				justify-content: space-between;

				.top_left {
					display: flex;
					font-size: 14px;

					.title {
						color: #999999;
					}

					.title:hover {
						cursor: pointer;
					}

					.img {
						margin: 0 8px;

						img {
							width: 8px;
							height: 8px;
						}
					}

					.sub_title {
						color: #333333;

						&:hover {
							cursor: pointer;
						}
					}
				}


				.top_right {
					display: flex;
					font-size: 14px;

					.item {
						margin-left: 46px;

						.item_img {
							img {
								width: 22px;
								height: 22px;
							}
						}

						.item_title {
							font-size: 14px;
							color: #333333;
						}

					}
				}
			}


			.carousel {
				margin-top: 24px;

			}
		}
	}

	.house_content {
		padding: 20px 0 32px;
		border-bottom: 1px solid #EEEEEE;

		.house_title {
			display: flex;
			justify-content: space-between;

			.house_title_left {
				.house_name {
					font-weight: bold;
					font-size: 18px;
					text-align: left;
					max-width: 700px;
					// word-break: break-all;
					// text-overflow: ellipsis;
					// display: -webkit-box;
					// -webkit-box-orient: vertical;
					// -webkit-line-clamp: 2;
					/* 这里是超出几行省略 */
					// overflow: hidden;
					line-height: 24px;
					word-break: break-all;
				}

				.house_two {
					display: flex;
					align-items: center;
					padding: 20px 0;

					.price {
						color: #E60012;
						font-size: 16px;
						font-weight: bold;
					}

					.address {

						display: flex;
						align-items: center;

						img {
							display: flex;
							align-items: center;
							width: 18px;
							height: 18px;
						}

						.address_detail {
							text-align: left;
							width: 500px;
							word-break: break-all;
							margin-left: 8px;
							line-height: 19px;
						}
					}
				}

			}

			.house_title_right {
				display: flex;
				justify-content: center;
				width: 164px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				background: #E60012;
				opacity: 1;
				border-radius: 20px;
				color: #FFFFFF;


				.btn {
					display: flex;
					align-items: center;

					img {
						width: 20px;
						height: 22px;

					}
				}

				.text {
					margin-left: 8px;
					font-size: 16px;
				}
			}
		}

		.house_info {
			display: flex;
			align-items: center;
			font-size: 14px;

			.house_view {
				display: flex;
				align-items: center;

				.house_view_img {
					display: flex;
					align-items: center;

					img {
						margin-right: 8px;
						width: 18px;
						height: 18px;
					}
				}
			}

			.house_time {
				// margin-left: 70px;
				color: #666666;
			}

		}
	}

	.all_info {
		display: flex;
		flex-wrap: wrap;
		padding: 32px 0;
		border-bottom: 1px solid #EEEEEE;

		.info_item {
			font-size: 14px;
			margin-right: 40px;
			margin-bottom: 16px;

			.info_item_title {
				color: #888888;
			}

			.info_item_content {
				margin-top: 8px;
				color: #333333;
			}
		}


	}

	.home_detail {
		padding-bottom: 32px;

		.home_detail_content {
			margin: 32px 0;
			line-height: 26px;
			color: #666666;
			font-size: 14px;
			text-align: left;
			word-break: break-all;
		}

		.home_detail_imgs {
			display: flex;
			flex-wrap: wrap;
			text-align: left;

			.detail_img {

				img {
					width: 273px;
					height: 192px;
					object-fit: cover;
					margin-right: 10px;
					margin-bottom: 10px;

					&:nth-of-type(4n) {
						margin-right: 0;
					}


				}
			}
		}
	}

	.status_btn {
		margin: 0 auto;
		width: 450px;
		height: 48px;
		line-height: 48px;
		text-align: center;
		opacity: 1;
		border-radius: 24px;
		font-size: 16px;

		&:hover {
			cursor: pointer;
		}
	}

	.apply_in {
		background: #F6F7F9;
		color: #333333;
	}

	.apply_success {
		background: rgba(65, 119, 221, 0.1);
		color: #4177DD;

	}

	.apply_success2 {
		margin-top: 24px;
		background: #4177DD;
		color: #FFFFFF;
	}

	.apply_fail {
		background: rgba(230, 0, 18, 0.1);
		color: #E60012;
	}

	.apply_btn {
		margin-top: 24px;
		background: #E60012;
		color: #FFFFFF;
	}
</style>
